import { EmailBody } from "@/models/EmailBody";

export const sendExternalEmail = async (emailBody: EmailBody) => {
    const emailHtml = `
        Name: ${emailBody.name}\n
        Email: ${emailBody.email}\n
        Message: ${emailBody.message}
    `;

    const data = {
      sender: { email: 'contact@neurobus.space' },
      to: [{ email: 'contact@neurobus.space' }],
      subject: 'Neurobus - Contact Form',
      htmlContent: emailHtml
    };
  
    try {
      const response = await fetch('https://api.sendinblue.com/v3/smtp/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'api-key': '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'
        },
        body: JSON.stringify(data)
      });
  
      if (response.ok) {
        console.log("The email was sent successfully!");
        alert("The email was sent successfully!");
      } else {
        throw new Error('An error occurred while sending the email');
      }
    } catch (error) {
      console.error(error);
      Error('An error occurred while sending the email');
    }

  };