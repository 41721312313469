import FAQ from '@/components/Faq'
import React, { FormEvent, useState } from 'react'
import ApplicationsCarousel from '@/components/ApplicationsCarousel'
import ValuesCarousel from '@/components/ValuesCarousel'
import TeamCards from '@/components/TeamCards'
import { EmailBody } from '@/models/EmailBody'
import { sendExternalEmail } from '@/services/externalEmail'
import Head from 'next/head'
import Image from 'next/image'
import useTypingEffect from '@/components/Typing'
import { useRouter } from 'next/router';
import { useEffect } from 'react';


export default function Home() {
  const [emailBody, setEmailBody] = useState(new EmailBody());
  const typingText = useTypingEffect(['efficient', 'accurate', 'responsive'], 100, 2000);
  const router = useRouter();

  // Function to handle form submission
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      // Send the email data to your server or email service using an HTTP request
      sendExternalEmail(emailBody)

      // Clear the form fields on successful submission
      setEmailBody(new EmailBody());

      // Display a success message or perform any other desired action
      console.log('Email sent successfully!');
    } catch (error) {
      // Handle any error that occurred during the email sending process
      console.error('Error sending email:', error);
    }
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navbarHeight = document.querySelector('nav')?.offsetHeight || 0;
      const sectionPosition = section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionPosition - navbarHeight;
  
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };

  const handleScrollSection = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.warn(`Element with ID ${sectionId} not found.`);
    }
  };

  const navigateToSection = (path: string, sectionId?: string) => {
    if (router.pathname !== path) {
      router.push(path).then(() => {
        if (sectionId) {
          scrollToSection(sectionId);
        }
      });
    } else if (sectionId) {
      scrollToSection(sectionId);
    }
  };

  useEffect(() => {
    if (router.query.scrollTo === 'contact' && router.query.source === 'products') {
      scrollToSection(router.query.scrollTo);
    }
  }, [router.query.scrollTo, router.query.source]);

  return <> 
      {
      <Head>
        <title>Neurobus - Pioneering Space</title>
        <meta name="description" content="As pioneers and trusted partners in the field of neuromorphic 
              technology for space and edge computing applications, Neurobus
              offers cutting-edge, low-power, high-performance solutions
              inspired by the brain's architecture. We're
              committed to advance these sectors by enhancing data
              processing, analytical capabilities, and operational
              efficiency." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:image" content="https://neurobus.space/assets/OG_Image.jpg" />
        <meta property="og:title" content="Neurobus 
                                            Pioneering Space, One Neuron at a Time" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://neurobus.space/" />
        <meta property="og:locale" content="en_GB"/>
      </Head>
      }
    
      <div className="home-hero" id="home">
        <Image
          src="/assets/globe1.png"
          width={2000}
          height={2000}
          loading="eager"
          alt="Neuro"
          className="hero__background"
        />
        <div className="hero__info">
          <div className="hero__text">
            <div className="hero__title">
              PIONEERING <span>space</span> <br/> 
              one <span>neuron</span> <br/> 
              at a time
            </div>
            <span className="hero__subtitle">
                We equip spacecraft with <span>AI</span> to improve power efficiency,
                throughput and performance.
            </span>
          </div>
          <div className="hero__button" onClick={() => handleScrollSection("technology")}>
            <span>Learn More</span>
          </div>
        </div>
      </div>
      
      <div className="technology" id="technology">
        <Image 
          className="technology__image"
          src="/assets/transcending.svg" 
          width={100}
          height={100}
          alt="Technology" />
        <div className="technology__image--mobile"></div>
        <h2 className="home__section-subject technology__section-subject">
          Technology
        </h2>
        <div className="home__section-title technology__title">
          Make space navigation <span>{typingText}</span>
        </div>
        <div className="technology__text">
          <span className="technology__paragraph">
            The progress of AI has leaned on increasing the number of
            transistors per unit area to manage power consumption while
            meeting escalating computing demands. As we approach the
            physical limits of transistor sizes, it becomes vital to
            explore <span className="technology__paragraph--span">
              alternative computing principles.
            </span>
          </span>
          <span className="technology__paragraph">
            <span className="technology__paragraph--span">Neuromorphic computing</span> is our answer 
              to this challenge. Inspired by biological
              systems like the retina and brain, we harness neuromorphic
              sensors, algorithms, and hardware that process information
              closer to how these biological models operate compared to
              conventional computer architectures.
          </span>
        </div>
      </div>
      <div className="home-applications" id="applications">
        <div className="home-applications__container">
          <div className="home-applications__section">
            <h2 className="home__section-subject products__section-subject">
              Products
            </h2>
            <div className="home-applications__text">
              Neurobus&apos; neuromorphic technology offers revolutionary
              applications across diverse domains:
              
              <div className="products__button" onClick={() => navigateToSection("/products")}>
                <span>Find Products</span>
              </div>

            </div>
          </div> 
          <ApplicationsCarousel />
        </div>
      </div>
      
      <div className= 'location_homepage'>
        <Image 
        src="/assets/location.png" 
        alt="location" 
        layout="responsive"
        width={200}
        height={200}
        />
        </div>

      <div className="home-why" id="why-us">
        <div className="home-why__container" id="why-us">
          <Image
            loading="eager"
            src="/assets/Why-us-graphic.svg"
            alt="Nervous Signals"
            width={100}
            height={100}
            className="home-why__image"
          />
          <div className="home-why--mobile">
            <div className="image__container">
              <Image
                loading="eager"
                src="/assets/Why-us-graphic-mobile.svg"
                alt="Nervous Signals"
                width={100}
                height={100}
                className="home-why__image--mobile"
              />
            </div>
            <div className="home-why__image--mobile-line"></div>
          </div>
          
          <div className="home-why--row">

            <div className="home-why--column">
              <h2 className="home__section-subject">
                They Trust Us
              </h2>
              
              <Image className="logo1 responsiveImage1" src="/assets/logos/airbus.png" width={60} height={20} alt="airbus" />
              <Image className="logo1 responsiveImage1" src="/assets/logos/cnes.png" width={50}height={50} alt="cnes" />
              <Image className="logo1 responsiveImage1" src="/assets/logos/esa.png" width={50} height={50} alt="esa" />
              
            </div>

            <div className="partners--column">
              <h2 className="home__section-subject">
                Partners
              </h2>
              
              <Image className="bpi responsiveImage1" src="/assets/logos/bpifrance.png" width={50} height={50} alt="bpifrance" />
              <Image className="logo responsiveImage1" src="/assets/logos/brainchip.png" width={50} height={50} alt="brainchip" />
              <Image className="logo responsiveImage2" src="/assets/logos/hec.png" width={40} height={40} alt="hec"/>
              <Image className="logo responsiveImage3" src="/assets/logos/intel.png" width={30} height={30} alt="intel" />
              <Image className="logo responsiveImage1" src="/assets/logos/prophesee.png" width={50} height={50} alt="prophesee" />
            </div>

          </div>
          
        </div>
      </div>

      <div className="home-contact" id="contact">
        <div className="home-contact__info">
        <div className="contact-ellipse-2"></div>
        <div className="contact-ellipse-3"></div>
          <div className="home-contact__text">
            <h2 className="home__section-subject">
              Contact
            </h2>
            <div className="home-contact__title home__section-title">
              Let’s Connect
            </div>
            <span className="home-contact__paragraph">
                Whether you have a question about our technology, want to
                explore partnership opportunities, or simply want to learn
                more about our mission, don&apos;t hesitate to reach out.
            </span>
          </div>
          <div className="home-contact__socials">
            <div>
              <Image
                src="/assets/linkedin.svg"
                alt="LinkedinIcon"
                width={24}
                height={24}
                className="home-contact__social"
              />
            </div>
            <div>
              <Image
                src="/assets/twitter.svg"
                alt="TwitterIcon"
                width={24}
                height={24}
                className="home-contact__social"
              />
            </div>
          </div>
        </div>
        <div className="home-contact__column">
        <div className="contact-ellipse-1"></div>
          We&apos;d love to hear from you.
          <form className="home-contact__form" onSubmit={handleSubmit}>
            <input
              className="home-contact__input"
              placeholder="Name"
              value={emailBody.name}
              onChange={(e) =>
                setEmailBody(new EmailBody(e.target.value, emailBody.email, emailBody.message))
              }
            />
            <input
              className="home-contact__input"
              placeholder="Email"
              value={emailBody.email}
              onChange={(e) =>
                setEmailBody(new EmailBody(emailBody.name, e.target.value, emailBody.message))
              }
            />
            <textarea
              className="home-contact__input home-contact__textarea"
              placeholder="Message"
              value={emailBody.message}
              onChange={(e) =>
                setEmailBody(new EmailBody(emailBody.name, emailBody.email, e.target.value))
              }
            />
            <button className="home-contact__button" type="submit">
              SUBMIT
              <object
                id="home-contact__button-arrow"
                data="/assets/button-arrow.svg"
                type="image/svg+xml"
                aria-label="Right Arrow"
              ></object>
            </button>
          </form>
        </div>
      </div>
    </>
  
}
