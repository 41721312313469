import React, { useRef } from 'react';
import CarouselArrow from './CarouselArrow';

const ApplicationsCarousel: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const slideNext = () => {
    if (carouselRef.current && childRef.current) {
      carouselRef.current.scrollBy({
        left: childRef.current.offsetWidth + 10,
        behavior: 'smooth',
      });
    }
  };

  const slidePrev = () => {
    if (carouselRef.current && childRef.current) {
      carouselRef.current.scrollBy({
        left: -childRef.current.offsetWidth - 10,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="carousel-container">
      <div className="carousel" ref={carouselRef}>
      <div className="home-applications__cards">
            <div className="home-applications__card card" ref={childRef}>
              <span className="home-applications__card-title1">
                Space Situational Awareness (SSA)
              </span>
              <div className="home-applications__card-description">
              Our neuromorphic solution provides real-time detection, tracking, and identification 
              capabilities at low power consumption, which is robust to difficult lighting conditions.
              </div>
            </div>
            <div className="home-applications__card card home-applications__card-2">
              <span className="home-applications__card-title">
                In Orbit Services
              </span>
              <span className="home-applications__card-description">
              Our low-latency systems allow accurate tracking and docking with other vessels in orbit. 
              The system can then act autonomously, removing the need for a human operator on the ground.
              </span>
            </div>
            <div className="home-applications__card card home-applications__card-3">
              <span className="home-applications__card-title1">
                Real-Time Space Navigation System
              </span>
              <span className="home-applications__card-description">
              We track stars and key landmarks with unprecedented temporal 
              accuracy, allowing for reliable, robust, and fine-grained navigation in space. 
              </span>
            </div>
          </div>
      </div>
      
      <div className="prev-button" onClick={slidePrev}>
        <CarouselArrow className="application-prev-button" image="/assets/left-arrow.svg" />
      </div>
      <div className="next-button" onClick={slideNext}>
        <CarouselArrow className="application-next-button" image="/assets/right-arrow.svg" />
      </div>

      <style jsx>{`
        .carousel-container {
          width: 62.5%;
          margin: 0 auto;
          position: relative;
        }
  
        .carousel {
          display: flex;
          overflow-x: hidden;
          scroll-snap-type: x mandatory;
          scroll-behavior: smooth;
          -webkit-overflow-scrolling: touch;
          width: 100%;
        }
  
        .prev-button,
        .next-button {
          position: absolute;
          top: 70%;
          transform: translateY(-0%);
          font-size: 14px;
          line-height: 1;
          padding: 0;
          color: #000;
          background-color: transparent;
          padding: 10px;
        }
  
        .prev-button {
          left: -100px;
          line-height: 0;
          padding-left: 6px;
          padding-top: 7px;
        }

        .next-button {
          left: -65px;
          line-height: 0;
          padding-left: 6px;
          padding-top: 7px;     
        }


        @media (max-width: 768px) {
          .carousel-container {
            width: 90%;
          }

          .prev-button,
          .next-button {
            top: 50%;
            left: 5px;
            right: 5px;
          }
        }

        @media (max-width: 480px) {
          .carousel-container {
            width: 100%;
          }

          .prev-button,
          .next-button {
            top: 50%;
            left: 5px;
            right: 5px;
          }
        }
        
      `}</style>
    </div>
  );
};

export default ApplicationsCarousel;