import { useEffect, useState } from 'react';

const useTypingEffect = (
  words: string[], 
  speed: number = 150,
  delay: number = 2000 
): string => { 
  const [displayedText, setDisplayedText] = useState<string>('');
  const [currentWordIndex, setCurrentWordIndex] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  useEffect(() => {
    let index = 0;
    const handleTyping = () => {
      const currentWord = words[currentWordIndex];
      setDisplayedText((prev) => {
        if (isDeleting) {
          if (prev.length === 0) {
            setIsDeleting(false);
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
            return '';
          }
          return currentWord.substring(0, prev.length - 1);
        } else {
          if (prev.length === currentWord.length) {
            setTimeout(() => setIsDeleting(true), delay);
            return prev;
          }
          return currentWord.substring(0, prev.length + 1);
        }
      });
    };

    const intervalId = setInterval(handleTyping, isDeleting ? speed / 2 : speed);

    return () => clearInterval(intervalId);
  }, [words, speed, delay, currentWordIndex, isDeleting]);

  return displayedText;
};

export default useTypingEffect;
