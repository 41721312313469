export class EmailBody {
    name: string;
    email: string;
    message: string;

    constructor(name = '', email = '', message = '') {
        this.name = name;
        this.email = email;
        this.message = message;
    }
}