import Image from "next/image";
import { FC, useEffect } from "react";

interface ArrowProps {
    image: string
    className: string;
}

const CarouselArrow: FC<ArrowProps> = (props: ArrowProps) => {

    useEffect(() => {
        const childDiv = document.querySelector(`.${props.className}`);
        const grandparentDiv = childDiv?.parentElement?.parentElement;
    
        childDiv?.addEventListener('mouseover', () => {
            grandparentDiv?.classList.add('hovered');
        });
    
        childDiv?.addEventListener('mouseout', () => {
            grandparentDiv?.classList.remove('hovered');
        });
    
    }, [props.className])

    return <>
        <div className={`container`}>
            <div className="border">
                <div className={`button ${props.className}`}>
                    <Image height={8} width={9} src={props.image} alt="arrow" />
                </div>
            </div>
        </div>
        <style jsx>{
            `.container {
                position: absolute;
                height: 28px;
                display: inline-block;
                border-radius: 50%;
                background: white;
                z-index: 10000000;
            }
              
            .border {
                padding: 1px;
            }
              
            .button {
                font-weight: 700;
                background-color: black;
                color: white;
                display:flex;
                align-items: center;
                padding: 9px 8px;
                border-radius: 4px;
                cursor: pointer;
                text-decoration: none;
                text-transform: uppercase;
                border-radius: 50%
            }

            .container.hovered{
                background: linear-gradient(26.5deg, #054A97 14.11%, #9CCAFD 52.2%, #087BFB 68.1%, #0662C9 87.07%);
            }`
        }
        </style>
    </>
}

export default CarouselArrow;